.page_select {
	color: #1f2937;
}
.video_info_container {
	position: fixed;
	top: 50px;
	left: 0;
	width: 100%;
	z-index: 1002;
	text-align: center;
}

.video_vision_board {
	position: absolute;
	top: 12px;
	left: 12px;
	width: calc(100vw - 388px);
	z-index: 1001;
}

.video_element_container {
	/*background-color: #eee;*/
}

.video_element {
	border-radius: 12px;
}

.video_test_sound {
	width: 30px;
	height: 30px;
	background-image: url('../../../assets/images/icons/video/video_play.svg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.video_test_sound:hover {
	opacity: 0.7;
}

.video_test_sound.on {
	background-image: url('../../../assets/images/icons/video/video_pause.svg');
}

.video_menu_box {
	border-bottom-right-radius: 25px;
	border-top-right-radius: 25px;
	padding: 13px 0;
	height: 50px;
	margin-right: 8px;
	cursor: pointer;
}

.video_menu_icon {
	width: 20px;
	height: 20px;
	margin: 0 12px 0 20px;
}

.video_menu_icon.tiny_icon {
	width: 30px;
	height: 30px;
	margin: 0 8px 0 15px;
}

.video_menu_box.selected {
	background-color: #60608b;
}

.video_mirrored {
	transform: scale(-1, 1);
}

.video_info {
	position: relative;
	white-space: pre-line;
	top: 24px;
	text-align: left;
	padding: 16px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 10px;
	background-color: #000;
	color: #fff;
	max-width: min(600px, 90vw);
	max-height: 90vh;
	overflow-y: hidden;
	z-index: 2001;
}

.video_toggle_size {
	width: 22px;
	height: 22px;
	/*margin-right: 12px;*/
	background: url('../../../assets/images/icons/video/search.svg');
	cursor: pointer;
}

.video_close_div {
	position: absolute;
	top: 14px;
	right: 14px;
	cursor: pointer;
}

.video_info_div {
	position: absolute;
	display: flex;
	align-items: center;
	z-index: 902;
	border-radius: 6px;
	background-color: #000;
	color: #fff;
	top: 6px;
	left: 8px;
	padding: 2px 4px;
}

.video_icon {
	width: 100%;
	height: 32px;
}

.video_notes {
	position: fixed;
	right: 12px;
	bottom: 90px;
	z-index: 2003;
	width: 340px;
	height: 600px;
	max-height: calc(100vh - 102px);
	border-radius: 12px;
}

.video_menu_page {
	position: fixed;
	width: 364px;
	/*min-height: 600px;*/
	bottom: 98px;
	right: calc(50vw - 182px);
	z-index: 1002;
	/*background-color: var(--dark_blue);*/
	color: #fff;
	border-radius: 12px;
}

.video_menu_left {
	width: 267px;
	background-color: #161634;
}

.video_menu_right {
	width: 533px;
	background-color: #161634;
}

.video_footer_icon {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 0 10px;
	text-align: center;
}

.video_footer {
	position: fixed;
	bottom: 0;
	width: 100vw;
	height: 90px;
	z-index: 1001;
	/*background: var(--medium_blue);*/
}

.test_sound_volume_container {
	display: flex;
	height: 16px;
	width: 100%;
}

.test_sound_volume_box {
	background-color: #5a5a7d;
	height: 16px;
	width: 1.5%;
	border-radius: 3px;
	margin-right: 2.5%;
}

.test_sound_volume_box.active {
	background-color: #94abff;
}

.video_extra_window {
	z-index: 1001;
	position: absolute;
	right: 0;
	top: 0;
	width: 390px;
	height: calc(100vh - 140px);
	background-color: #000024;
}

@media only screen and (max-width: 745px) {
	.video_vision_board {
		width: calc(100vw - 24px);
	}
}

@media only screen and (max-width: 800px) {
	.video_menu_page {
		width: calc(100vw - 32px);
		right: 16px;
		bottom: 90px;
	}
	.video_menu_left {
		width: 100vw;
	}

	.video_menu_right {
		width: 100vw;
		background-color: unset;
	}
	.video_footer_icon {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin: 0 6px;
	}
	.video_extra_window {
		z-index: 1001;
		width: 100vw;
	}
}
